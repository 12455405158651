<template>
	<span class="cs-color-split">
	    <span v-if="text[0]">{{text[0]}}</span>
	    <span v-if="text[1]">{{text[1]}}</span>
	</span>
</template>

<script>
	
export default {
    name: "CSUtilsColorSplit",
    props: {
        data: String,
    },
    computed: {
        text(){
        	if (this.data?.length > 0) {
				let text = this.data.split(' ');
				let counter = text?.length ? text.length : 0;
				if(counter > 2){
					return [
						text.slice(0, parseInt(counter/2)).join(' '),
						text.slice(parseInt(counter/2)).join(' ')
					]
				}else{
					return [...text,'']
				}
			}

			return [];
        }
    }
};
</script>

<style lang="scss" scoped>
	// span {
	// 	>span:nth-child(2){
	// 		color: $title_color_green !important;
	// 	}
	// }
</style>
